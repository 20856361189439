import React from "react";
import { Page } from "components/Page";
import { Link } from "gatsby";
import { Tile } from "components/Tile";

const EventsPage = () => {
  return (
    <Page>
      <div className="container mx-auto mt-16">
        <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
          <Link to="/events/all" className="no-underline">
            <Tile
              delay={0.1}
              title="All events"
              imageUrl="/event-logo-all.png"
            />
          </Link>
          <Link to="/events/fresh-festival">
            <Tile delay={0.2} imageUrl="/event-logo-festival.png"></Tile>
          </Link>
          <Link to="/events/letsdisko">
            <Tile delay={0.3} imageUrl="/event-logo-letsdisko.png"></Tile>
          </Link>
          <Link to="/events/athena-sit-down-social">
            <Tile delay={0.4} imageUrl="/event-logo-athena.png"></Tile>
          </Link>
          <Link to="/events/student-race-day">
            <Tile delay={0.5} imageUrl="/event-logo-race-day.png"></Tile>
          </Link>
        </div>
      </div>
    </Page>
  );
};

export default EventsPage;
