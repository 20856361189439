import { MotionImage } from "components/MotionImage";
import { motion } from "framer-motion";
import React from "react";

export function Tile({ imageUrl, title = "", delay = 0 }) {
  return (
    <motion.div
      className="flex flex-col py-8 px-12 sm:px-16 xl:px-16 h-64 min-h-full items-center justify-center rounded-xl "
      style={{ background: "#f7f7f7" }}
      animate={{ scale: 1, transition: { duration: 0.4, delay } }}
      initial={{ scale: 0.93 }}
      whileHover={{
        background: "var(--pink)",
        scale: 1.035,
        transition: { duration: 0.25 },
      }}
    >
      <MotionImage
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{
          opacity: 1,
          scale: 1,
          transition: { duration: 0.5, delay: delay + 0.2 },
        }}
        src={imageUrl}
        className="relative max-h-full max-w-full object-cover"
      />
      {title && (
        <motion.h3
          initial={{ opacity: 0, y: 10 }}
          animate={{
            opacity: 1,
            y: 0,
            transition: { duration: 0.5, delay: delay + 0.3 },
          }}
          className="mt-4 text-gray-800"
        >
          {title}
        </motion.h3>
      )}
    </motion.div>
  );
}
